<template>
  <v-content class="stamp">
    <div class="pa-3" v-show="data" ref="stamplist">
      <v-card class="mx-auto py-0 pl-0 mb-6 stampWrap" v-for="item in data" :key="item.id" @click="routerBindTo({ name: 'stamp_detail', query: { stamp_id: item.id, shop_id: item.shop_id, selected_shop: selected_shop}})">
        <v-row class="flex-nowrap" no-gutters align="center">
          <v-col cols="5" class="py-0">
            <v-img v-if="item.image_path" :src="item.image_path" lazy-src="@/assets/noimg@2x.png" class="noleftrounded" height="180" width="150">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-img v-else src="@/assets/noimg@2x.png" lazy-src="@/assets/noimg@2x.png" class="noleftrounded" height="180" width="150">
            </v-img>
          </v-col>

          <v-col cols="6" class="pa-3 pr-0">
            <h1 class="body-2 font-weight-bold mb-2">{{item.title}}</h1>
            <p class="overline" v-if="item.expiration_date">有効期限：{{item.expiration_date}}<br>
            対象店舗：{{item.shop_names}}</p>
            <p class="overline" v-else>対象店舗：{{item.shop_names}}</p>
            <v-btn
              v-if="item.auto_flg == 0"
              rounded
              depressed
              class="maincolor-bg btn_small w90 fontsize13 font-weight-bold"
              @click.stop="toStampAuth(item.id, item.auth_type, item.shop_id)"
            >スタンプGET</v-btn>
          </v-col>

          <v-col cols="1" class="pa-0">
            <v-icon x-small right class="maincolor">fas fa-chevron-right</v-icon>
          </v-col>
        </v-row>

        <v-overlay absolute opacity="0.7" v-if="item.is_expiration === 0">
          <div class="text-center">
            <img class="img" src="@/assets/sandtimer.svg" width="30">
            <p class="flgtext">有効期限切れ</p>
          </div>
        </v-overlay>
      </v-card>
    </div>

    <!-- InfiniteLoading -->
    <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
      <div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
      <div slot="no-more"></div>
      <div slot="no-results">利用できるスタンプはありません</div>
    </infinite-loading>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
export default {
  components: {
    InfiniteLoading,
  },
  data: () => ({
    selected_shop: 'すべて',
    init: [],
    page: 1,
    result: null,
    data: null,
    loading: false,
  }),
  created () {

    this.init = this.storageGet('*')

    //console.log('init!', this.init);
    var selected_shop = this.getUrlGetParam('selected_shop');

    if (selected_shop) {
        this.selected_shop = selected_shop
    } else {
        this.selected_shop = '全て'
    }
  },
  methods: {
    async infiniteHandler() {
        var shop_id = this.getUrlGetParam('shop_id');

        var params = {
            'page': this.page
        }

        if (shop_id) {
            params['shop_id'] = shop_id
        }else{
            params['shop_id'] = this.init.sid
        }

        var reqest_params = { params: params}

        var res = await this.apiCall('/stamp/top-list', reqest_params)

        if (res.data && res.data.length > 0) {
          if (this.page == 1) {
              this.$set(this, 'result', res)
              this.$set(this, 'data', res.data)
          } else {
              this.data = this.data.concat(res.data);
          }
          this.page = this.page + 1
          this.$refs.infiniteLoading.stateChanger.loaded()
        } else {
            this.$refs.infiniteLoading.stateChanger.complete()
        }

        this.setImageHeight();

    },
    toStampAuth(id, auth_type, shop_id){
      this.loading = true
      var destination = null
      var screenID = null
      switch (auth_type) {
        case 1:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
        case 2:
          destination = 'stampAuthQrActivity'
          screenID = 'stamp_auth_qr'
          break;
        case 3:
          destination = 'stampAuthBeaconActivity'
          screenID = 'stamp_auth_beacon'
          break;
        case 4:
          destination = 'stampAuthWifiActivity'
          screenID = 'stamp_auth_wifi'
          break;
        default:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
      }
      var tonative_req = {
        'destination': destination,
        'feature_id': 'stamp',
        'screen_id': screenID,
        'transition_type': 3,
        'stamp_id': id,
        'shop_id': shop_id,
      };

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }else{
        this.loading = false
      }
    },
    async setImageHeight() {
      await (this.$refs.stamplist);

      let stampWraps = this.$refs.stamplist.getElementsByClassName('stampWrap');
      let stampImages = this.$refs.stamplist.getElementsByClassName('v-image');
      for (let i = 0; i <= stampWraps.length; i++) {
        stampImages[0].style.height = stampWraps[0].offsetHeight + 'px';
      }
    }
  }
}
</script>

<style scoped>
.noleftrounded {
  border-radius: 10px 0 0 10px;
}
.viewflg_label {
  position: absolute;
  left: 5px;
  bottom: 5px;
}
.stamp_shops {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
</style>
<style>
.v-application .overline {
  word-break: break-all !important;
  line-height: 1rem;
  letter-spacing: initial !important;
}
.v-application {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.expiration_date_text {
  font-size: 9px;
  line-height: 1.5em;
}
</style>
